<template>
  <el-card class="box-card">
    <el-row>
      <el-descriptions class="margin-top" title="" :column="3">
        <el-descriptions-item label="优惠券名">{{ couponObj.name }}</el-descriptions-item>
        <el-descriptions-item label="适用范围">{{ couponObj.range_of_application | formatRange }}</el-descriptions-item>
        <el-descriptions-item label="优惠券类型">{{ couponObj.preferential_type | formatType }}</el-descriptions-item>
        <el-descriptions-item label="发放时间">{{
            couponObj.grant_begin_time | formatDate
          }}-{{ couponObj.grant_end_time | formatDate }}
        </el-descriptions-item>
        <el-descriptions-item label="审核状态">
          <span v-if="couponObj.state === 0">待审核</span>
          <span v-else-if="couponObj.state == 1">已审核</span>
          <span v-else-if="couponObj.state == 2">已驳回</span>
        </el-descriptions-item>
        <el-descriptions-item label="汇总">已领取{{ couponObj.getCount }}张,已使用{{ couponObj.useCount }}张,尚有{{
            couponObj.unusedCount
          }}张未用
        </el-descriptions-item>
      </el-descriptions>
    </el-row>
    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="user_id"
          label="用户名">
      </el-table-column>
      <el-table-column align="center" label="领取时间">
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="使用时间">
        <template slot-scope="scope">
          <div>{{ scope.row.use_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="order_sn"
          label="关联订单">
      </el-table-column>
      <el-table-column
          align="center"
          prop="price_paid"
          label="订单实付金额">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetCouponUser} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
    formatRange(val) {
      console.log(val)
      if (val == 1) {
        return '限定分类'
      } else if (val == 2) {
        return '限定商品'
      } else if (val == 3) {
        return '限定店铺'
      } else {
        return '通用'
      }
    },
    formatType(val) {
      if (val == 1) {
        return '折扣'
      } else if (val == 2) {
        return '运费'
      } else if (val == 3) {
        return '赠品'
      } else {
        return '满减'
      }
    }
  },
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      couponObj: {},
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetCouponUser(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.CouponGetLogList
          this.couponObj = res.data.coupon
          this.count = res.data.count
          this.couponObj.getCount = res.data.getCount
          this.couponObj.unusedCount = res.data.unusedCount
          this.couponObj.useCount = res.data.useCount
        }
      })
      this.listLoading = false
    },
    // 状态
    formatterStateOrder: function (row) {
      return row.state_order == '0' ? '已下单'
          : row.state == '1' ? '已结束'
              : row.state == '2' ? '已评价'
                  : row.state == '3' ? '已退款'
                      : row.state == '4' ? '已退货'
                          : row.state == '-1' ? '已接受'
                              : '暂无'
    },
  }
}

</script>
<style>
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
